import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { logout } from "@ui/Auth/api";
import Emitter from "@ui/Utils/CustomEventEmitter";
import enums from "helpers/enums";
import { fetchValidProducts } from "views/ExpiryLinks/api";
import { fetchCategories } from "./categoriesSlice";
import { fetchSubCategories } from "./subCategoriesSlice";
import { fetchCollectionLines } from "./collectionLinesSlice";
import { safeParseJSON } from "@ui/Utils/helper";

const pageSize = 50;

const initialState = {
  products: [], // List of product objects
  page: 1,

  paginationInfo: {}, // Additional pagination details
  status: "idle", // 'idle' | 'loading' | 'failed'
  productsCriteria: {}, // Store the current filter criteria
};

// Async thunk to fetch products
export const fetchProducts = createAsyncThunk(
  "main/fetchProducts",
  async (
    { page, productsCriteria },
    { rejectWithValue, dispatch, getState }
  ) => {
    const shortCode = window.location.pathname.split("/")[1];

    try {
      const res = await fetchValidProducts({
        page,
        pageSize,
        criteria: { productsCriteria, shortCode },
      });

      // Store division and catalog info in sessionStorage
      if (res.accessProvided?.division) {
        window.sessionStorage.setItem(
          enums.sessionStorage.division,
          JSON.stringify(res.accessProvided.division)
        );
      }
      if (res.accessProvided?.catalogues?.length) {
        const existingCatalogues = safeParseJSON(
          window.sessionStorage.getItem(
            enums.sessionStorage.allowedCatalogues
          ) || "[]"
        );

        const newCatalogues = res.accessProvided.catalogues;

        // Compare arrays to check if they are different
        const isDifferent =
          existingCatalogues.length !== newCatalogues.length ||
          existingCatalogues.some(
            (catalogue) => !newCatalogues.includes(catalogue)
          );

        const categories = getState().categories; // as this is required fields, this will be enough for now :P
        // , subCategories = getState().subCategories,collectionLines = getState().collectionLines;

        if (isDifferent || !categories.length) {
          // Update sessionStorage with new catalogues
          window.sessionStorage.setItem(
            enums.sessionStorage.allowedCatalogues,
            JSON.stringify(newCatalogues)
          );

          // Dispatch actions to fetch categories, subcategories, and collection lines
          dispatch(fetchCategories({ allowedCatalogues: newCatalogues }));
          dispatch(fetchSubCategories({}));
          dispatch(fetchCollectionLines({ allowedCatalogues: newCatalogues }));
        }
      }

      // Handle expired links
      if (res.expiredLink) {
        Emitter.emit("alert_error", "Contact Admin. Session Expired");
        logout("data-engine", "/expired");
        return rejectWithValue("Session expired");
      }

      return {
        products: res.products,
        paginationInfo: res.paginationInfo,
        page,
      };
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  }
);

const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setProductsCriteria: (state, action) => {
      state.productsCriteria = action.payload;
    },
    clearProducts: (state) => {
      state.products = [];
      state.paginationInfo = {};
      state.page = 1;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        const { products, paginationInfo, page } = action.payload;

        // Replace products if it's the first page (new filter criteria)
        if (page === 1) {
          state.products = products;
        } else {
          // Append products for pagination
          state.products = [...state.products, ...products];
        }

        state.paginationInfo = paginationInfo;
        state.page = page;
        state.status = "idle";
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setPage, setPageSize, setProductsCriteria, clearProducts } =
  mainSlice.actions;
export default mainSlice.reducer;
