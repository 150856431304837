import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import ProductCard from "./ProductCard";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, setPage } from "redux/linkitSlices/mainSlice";
import { getLinkItProductsMongooseCriteria } from "./SearchFilter";
import enums from "helpers/enums";
import { useParams } from "react-router-dom";

const ProductList = ({
  paginationInfo,
  onAddToCart,
  cart,
  onRemoveFromCart,
  gridView,
  isMobile,
  page,
}) => {
  const puritiesMaster = useSelector((state) => state.purities) || [];
  const masterData = {
    goldPricingList: useSelector((state) => state.metals) || [],
    diamondPricingList: useSelector((state) => state.diamonds) || [],
  };

  const main = useSelector((state) => state.main) || [];
  const products = main.products;
  const loadStatus = main.status;

  const dispatch = useDispatch();
  const listInnerRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false); // Local state to manage fetch status
  const params = useParams();

  const fetchNextPage = () => {
    if (
      params.code &&
      paginationInfo.totalPages !== paginationInfo.currentPageNo &&
      !isFetching
    ) {
      const localSessionProductFilter = sessionStorage.getItem(
        enums.sessionStorage.lastCriteria
      );
      let productsCriteria;
      const nextPage = paginationInfo.currentPageNo + 1;

      setIsFetching(true); // Set fetching to true to prevent multiple calls

      if (
        !Object.values(productsCriteria || {}).find((val) => val) &&
        localSessionProductFilter
      ) {
        productsCriteria = JSON.parse(localSessionProductFilter);

        dispatch(
          fetchProducts({
            page: nextPage,
            productsCriteria:
              getLinkItProductsMongooseCriteria(productsCriteria),
          })
        ).finally(() => setIsFetching(false)); // Reset fetching state after completion
      } else {
        dispatch(setPage(nextPage));
        dispatch(
          fetchProducts({
            page: nextPage,
          })
        ).finally(() => setIsFetching(false)); // Reset fetching state after completion
      }
    }
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      // Trigger fetch when scrolled near the bottom (90% of scrollHeight)
      if (scrollTop + clientHeight >= scrollHeight * 0.8 && !isFetching) {
        fetchNextPage();
      }
    }
  };

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div
      ref={listInnerRef}
      onScroll={onScroll}
      style={{
        overflowY: "auto",
        height: "100vh",
        position: "fixed",
      }} // Make container scrollable
    >
      <Grid
        container
        // spacing={2} // nin akkan.. shaking card the only issue :|
        style={{
          padding: "4px",
        }}
      >
        {products.map((product, index) => (
          <Grid
            item
            xs={gridView ? 6 : 12} // 2 columns in grid view on mobile, 1 in list view
            sm={gridView ? 6 : 12} // Same for small screens
            md={gridView ? 3 : 6} // 4 columns in grid view on medium, 2 in list view
            lg={gridView ? 2 : 4} // 6 columns in grid view on large, 2 in list view
            key={product._id || index} // Prefer unique keys
          >
            <ProductCard
              isMobile={isMobile}
              // prodIndex={index}
              paginationInfo={paginationInfo}
              masterData={masterData}
              puritiesMaster={puritiesMaster}
              product={product}
              // products={products}
              onAddToCart={onAddToCart}
              cart={cart}
              onRemoveFromCart={onRemoveFromCart}
            />
          </Grid>
        ))}
      </Grid>

      {products.length ? (
        <center>
          <pre
            // ref={ref}
            style={{
              position: "relative",
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
              fontSize: "20px",
              fontWeight: "bold",
              color: "#333",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              padding: "20px",
              marginBottom: "250px",
            }}
          >
            {loadStatus === "loading" &&
            paginationInfo.totalPages !== paginationInfo.currentPageNo ? (
              <center>Loading more...</center>
            ) : paginationInfo.currentPageNo &&
              paginationInfo.totalPages === paginationInfo.currentPageNo ? (
              <center>End of Catalogue...</center>
            ) : null}
          </pre>
        </center>
      ) : null}
    </div>
  );
};

export default ProductList;
